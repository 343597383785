import React from "react";
import Layout from "../other/layout";
import SEO from "../other/seo";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Img from "gatsby-image";
import FadeIn from 'react-fade-in';
import { graphql, useStaticQuery } from "gatsby";
const GrowYourBlogCommunity = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      events: file(relativePath: { eq: "blog4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, maxHeight: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      events1: file(relativePath: { eq: "blog4-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, maxHeight: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }      
    }
  `);
  return (
    <Layout>
      <FadeIn>
        <SEO title={intl.formatMessage({ id: "innerpage-108" })} />
        <div>
          <FadeIn delay={200} transitionDuration={700}>
            <div className="mainlyt">
              <Img fluid={data.events.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                className="img-responsive"
              />
            </div>
          </FadeIn>
          <div>
            <div className="bodyTextCss">
              <FadeIn className="BodyContainer bodytext" delay={210} transitionDuration={500}>
                <div className="">
                  <div className="assessment">{intl.formatMessage({ id: "blog-4-1" })}</div>
                </div>

                <p>{intl.formatMessage({ id: "blog-4-2" })}</p>
                <p>{intl.formatMessage({ id: "blog-4-3" })}</p>
                <div><Img fluid={data.events1.childImageSharp.fluid}
                  objectFit="cover"
                  objectPosition="50% 50%"
                  alt=""
                className="img-responsive"
              /></div>
              <br/>
                <p>{intl.formatMessage({ id: "blog-4-4" })}</p>
                <p>{intl.formatMessage({ id: "blog-4-5" })}</p>
                <h6>{intl.formatMessage({ id: "blog-4-6" })}</h6>
                <p>{intl.formatMessage({ id: "blog-4-7" })}</p>
               
              </FadeIn>
            </div>

          </div>
        </div>
      </FadeIn>
    </Layout>

  );
};

export default GrowYourBlogCommunity;
